<template lang="pug">
include ../../../configs/template
div.row.mt-3.text-left
  div(v-if="statement.status_dkk && statement.status_dkk.documents.length && statement.is_continue === TYPE_CONDITIONAL_CONSTANTS.CONFIRM_RANK_SQC && statement.status_document.id === STATUSES_STATEMENT_CONSTANTS.SAILOR.QUALIFICATION.STATEMENT.IN_PROGRESS").col-sm-12.mt-3
    label(:class="{ 'ml-3': checkAccess('backOffice') }") {{ $t('existsDocs') }}:
    TableChanges(v-if="checkAccess('backOffice')" :statement="statement")
  div.col-sm-12
    v-btn(@click="saveDocument(statement)" :loading="isLoading" color="primary") {{ $t('saveDoc') }}
  div.col-sm-12.col-md-6
    +data-info('number', 'statement.number')
  div.col-sm-12.col-md-6
    +data-info('rank', 'getDirectoryObject({value: "ranks", id: statement.rank})[nameLang]')
  div(v-if="statement.port").col-sm-12.col-md-6
    +data-info('port', 'getDirectoryObject({value: "ports", id: statement.port})?.[nameLang]')
  div.col-sm-12.col-md-6
    b {{ $t('position') }}:
    span(v-for="position in statement.list_positions" :key="position.id") {{ getDirectoryObject({ value: 'positions', id: position})?.[nameLang] }};
  div(v-if="statement.date_meeting").col-sm-12.col-md-6
    +data-info('dataEvent', 'getDateFormat(statement.date_meeting)')
  div.col-sm-12.col-md-6
    b {{ $t('protocolSQC') }}:
    div(v-if="statement.protocol_dkk")
      span.mr-1 {{ statement.protocol_dkk.number }} /
      span(v-for="position of statement.protocol_dkk.position" :key="position.id") {{ position[nameLang] }}
      router-link(:to="{ name: 'sqc-protocols-info', params: { documentID: statement.protocol_dkk.id }}").ml-1 ({{ $t('openProtocol') }})
    div(v-else) {{ $t('noProvided') }}
  div(v-if="checkAccess('backOffice')").col-sm-12.col-md-6
    +data-info('typeDoc', 'isContinueStatement')
  div(v-if="checkAccess('document-author-view') && statement.created_by").col-sm-12.col-md-6
    +data-info('recordAuthor', 'statement.created_by.name')
  div(v-if="checkAccess('document-author-view') && statement.created_by").col-sm-12.col-md-6
    +data-info('createDate', 'statement.created_by.date')
  div.col-sm-12.col-md-6
    +data-info('payment', 'paymentStatusByStatus(statement.is_payed)[0][nameLang]')
  div.col-sm-12.col-md-6
    +data-info('status', 'statusDocumentName')(:class="getStatus(statement.status_document)")
  div(v-if="statement?.status_document === STATUSES_STATEMENT_CONSTANTS.SAILOR.QUALIFICATION.STATEMENT.REJECTED").col-sm-12.col-md-6.text-left.d-flex
    div(v-if="statement.comments?.length").col-sm-12
      h5.p-0.m-0 {{$t('rejectionReasonStatement')}}:
      v-divider
      div(v-for="(comment, index) in statement.comments" :key="comment.id").ml-4
        div
          +data-info('rejectionReasonStatement','getDirectoryObject({ id: comment.additional_info.reason, value: "rejectionReasons" })[nameLang]')
        div(v-if="checkAccess('backOffice')").pt-2.py-3
          +data-info('createdBy', 'comment.created_by.name')
        div.pt-2.py-3
          +data-info('dateCreate', 'new Date(comment.created_by.date).toLocaleString()')
        div.pt-2.py-3
          +data-info('reasonComment', 'comment.comment')
        v-divider(v-if="index !== statement.comments.length-1")

  div.col-sm-12
    b {{ $t('existsDocs') }}:
    TableV2(
      :headers="fieldsExistDocuments"
      :items="statement.status_dkk.exists_docs"
      isHiddenTableCount
      isHiddenPageCount
      isHiddenTableSearch)
  div.col-sm-12
    b {{ $t('notExistsDocs') }}:
    TableV2(
      :headers="fieldsApplication"
      :items="statement.status_dkk.documents"
      isHiddenTableCount
      isHiddenPageCount
      isHiddenTableSearch)
  div(v-if="checkAccess('qualificationStatement', 'viewExperienceTable', statement)").col-sm-12
    b {{ $t('experience') }}: {{ $t('noCheck') }}
    TableV2(
      :headers="fieldsExperience"
      :items="statement.status_dkk.experince"
      isHiddenTableCount
      isHiddenPageCount
      isHiddenTableSearch)
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { getDateFormat, getStatus, getDirectoryObject } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { TYPE_CONDITIONAL_CONSTANTS, STATUSES_STATEMENT_CONSTANTS } from '@/configs/constants'

export default {
  name: 'SailorQualificationStatementInfo',
  components: {
    TableChanges: () => import('./TableChanges')
  },
  props: {
    statement: { type: Object, default: () => ({}) },
    comments: { type: Array, default: () => ([]) }
  },
  data () {
    return {
      TYPE_CONDITIONAL_CONSTANTS,
      STATUSES_STATEMENT_CONSTANTS,
      fieldsExistDocuments: [
        { value: 'type_doc', text: this.$t('typeDoc'), sortable: false },
        { value: 'info', text: this.$t('data'), sortable: false, tdClass: 'ellipsis' },
        { value: 'number', text: this.$t('number'), sortable: false },
        { value: 'name_issued', text: this.$t('issued'), sortable: false, tdClass: 'ellipsis' },
        { value: 'date_start', text: this.$t('dateIssue') },
        { value: 'date_end', text: this.$t('dateEnd') },
        { value: 'statusExistsDocs', text: this.$t('status'), sortable: false }
      ],
      fieldsApplication: [
        { value: 'type_document', text: this.$t('typeDoc'), sortable: false },
        { value: 'document_descr', text: this.$t('nameDoc'), sortable: false },
        { value: 'standarts_text', text: this.$t('requirements'), sortable: false }
      ],
      fieldsExperience: [
        { value: 'experience_descr', text: this.$t('experience'), sortable: false },
        { value: 'standarts_text', text: this.$t('standards'), sortable: false },
        { value: 'statusExperience', text: this.$t('status'), sortable: false }
      ],
      sortByA: 'status',
      sortDescA: true,
      isLoading: false,
      getDateFormat,
      getStatus,
      checkAccess,
      rejectStatusId: 23
    }
  },
  computed: {
    ...mapGetters(['paymentStatusByStatus', 'rejectionReasonByID', 'getDirectoryObject']),
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    isContinueStatement () {
      if (this.statement.is_continue) return this.$t('confirmation')
      else return this.$t('appropriation')
    },
    statusDocumentName () {
      return getDirectoryObject({ value: 'statuses', id: this.statement.status_document })[this.nameLang]
    }
  },
  methods: {
    ...mapActions(['saveQualificationDocument']),
    async saveDocument () { // Generate document for application qualification
      this.isLoading = true
      let data = {
        body: { statement_id: this.statement.id },
        number: this.statement.number,
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      }
      await this.saveQualificationDocument(data)
      this.$notification.success('notify.success.downloadGenerateFile')
      this.isLoading = false
    }
  }
}
</script>
